import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { client } from "../sanity";
import "../App.scss";
const BlockContent = require("@sanity/block-content-to-react");

const About = styled.div`
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 300;
  font-size: 18px;

  h1,
  h2,
  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }
  h2,
  h3 {
    margin-top: 4rem;
  }

  max-width: 800px;
  @media (max-width: 800px) {
    max-width: 360px;
  }
`;

const serializers = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    undefined: (props) => {
      return null;
    },
  },
};

function Om() {
  const [about, setAbout] = useState({});

  useEffect(() => {
    const fetchPosts = () => {
      client
        .fetch(`*[_type == $type][0]`, {
          type: "league",
        })
        .then((res) => {
          console.log("RES", res);
          setAbout(res.about);
        })
        .catch((err) => {
          console.log("err", err);
          console.error("Oh no, error occured: ", err);
        });
    };
    fetchPosts();
  }, []);

  return (
    <About>
      {about && <BlockContent blocks={about} serializers={serializers} />}
    </About>
  );
}

export default Om;
